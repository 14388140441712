<template lang="pug">
    div.navbar
        //- 点击收回左菜单
        hamburger(:toggle-click="toggleSideBar"
                    :is-active="sidebar.opened").hamburger-container
        //- 面包屑
        breadcrumb.breadcrumb-container
        
        //- 
        div.right-menu
            span.errLog-container(style="padding-right:20px;") 你好, {{$store.getters.name}}
            Screenfull.screenfull.right-menu-item
            SizeSelect.right-menu-item
            el-dropdown.avatar-container.right-menu-item(trigger="click")
                div.avatar-wrapper: img(:src="avatar").user-avatar
                el-dropdown-menu(slot="dropdown")
                    el-dropdown-item: router-link(to="/") 首页
                    el-dropdown-item(divided): router-link(to="/profile/password") 密码修改   
                    el-dropdown-item(divided): span(style="display:block;" @click="logout") 退出

</template>

<script>
import { mapGetters } from "vuex";
import Breadcrumb from "@/components/breadcrumb";
import Hamburger from "@/components/hamburger";
import Screenfull from "@/components/screen-full";
import SizeSelect from "@/components/size-select";
// import LangSelect from '@/components/LangSelect'
// import ThemePicker from '@/components/ThemePicker'
import avatar from "@/assets/logo.png";

export default {
  components: {
    Breadcrumb,
    Hamburger,
    Screenfull,
    SizeSelect
    // LangSelect,
    // ThemePicker
  },
  data() {
    return {
      avatar: avatar
    };
  },
  computed: {
    ...mapGetters(["sidebar", "name", "device"]) //, 'avatar'
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch("toggleSideBar");
    },
    async logout() {
      await this.$store.dispatch("Logout");
      this.$router.push({ path: "/login" });
      // location.reload( ); // In order to re-instantiate the vue-router object to avoid bugs
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.navbar {
  height: 50px;
  line-height: 50px;
  border-radius: 0px !important;
  .hamburger-container {
    line-height: 58px;
    height: 50px;
    float: left;
    padding: 0 10px;
  }
  .breadcrumb-container {
    float: left;
  }
  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }
  .right-menu {
    float: right;
    height: 100%;
    &:focus {
      outline: none;
    }
    .right-menu-item {
      display: inline-block;
      margin: 0 8px;
    }
    .screenfull {
      height: 20px;
    }
    .international {
      vertical-align: top;
    }
    .theme-switch {
      vertical-align: 15px;
    }
    .avatar-container {
      height: 50px;
      margin-right: 30px;
      .avatar-wrapper {
        cursor: pointer;
        margin-top: 5px;
        position: relative;
        .user-avatar {
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }
        .el-icon-caret-bottom {
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
