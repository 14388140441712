<template lang="pug">
    div(:class="classObj").app-wrapper
        div.drawer-bg(v-if="device==='mobile'&&sidebar.opened" @click="handleClickOutside")
        sidebar.sidebar-container
        div.main-container
            navbar
            tags-view
            app-main

</template>

<script>
import { Navbar, AppMain, Sidebar, TagsView } from "./components";
import ResizeMixin from "./mixin/resize-handler";
export default {
  name: "Layout",
  components: { Navbar, AppMain, Sidebar, TagsView },
  mixins: [ResizeMixin],
  computed: {
    sidebar() {
      return this.$store.state.app.sidebar;
    },
    device() {
      return this.$store.state.app.device;
    },
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === "mobile"
      };
    }
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch("closeSideBar", { withoutAnimation: false });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/styles/mixin.scss";
.app-wrapper {
  @include clearfix;
  position: relative;
  height: 100%;
  width: 100%;
  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}
.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}
</style>
