<template lang="pug">
    el-scrollbar(wrap-class="scrollbar-wrapper")
        el-menu(:show-timeout="200"
                :default-active="$route.path"
                :collapse="isCollapse"
                mode="vertical"
                background-color="#304156"
                text-color="#bfcbd9")
            sidebar-item(v-for="route in permission_routers" 
                        :key="route.path" 
                        :item="route" 
                        :base-path="route.path")
</template>
<script>
import { mapGetters } from "vuex";
import SidebarItem from "./sidebar-item";

export default {
  components: { SidebarItem },
  computed: {
    ...mapGetters(["permission_routers", "sidebar"]),
    isCollapse() {
      return !this.sidebar.opened;
    }
  }
};
</script>
