import Vue from "vue";
import VueRouter from "vue-router";
import routeJson from "./route.json";

/* Layout */
import Layout from "@/views/layout";

Vue.use(VueRouter);

export const constantRouterMap = [
  {
    path: "/redirect",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/redirect/:path*",
        component: () => import("@/views/redirect/index")
      }
    ]
  },
  {
    path: "/login",
    component: () => import("@/views/login"),
    hidden: true
  },
  {
    path: "/auth-redirect",
    component: () => import("@/views/login/authredirect"),
    hidden: true
  },
  {
    path: "",
    component: Layout,
    redirect: "dashboard",
    children: [
      {
        path: "dashboard",
        component: () => import("@/views/dashboard/index"),
        name: "Dashboard",
        meta: { title: "首页", icon: "dashboard", noCache: true }
      }
    ]
  }
];

export default new VueRouter({
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
});

const parseJson = () => {
  const mapArray = routeJson;
  for (let i = 0; i < mapArray.length; i++) {
    const item = mapArray[i];
    item.component = Layout;
    if (item.children && item.children.length > 0) {
      for (let j = 0; j < item.children.length; j++) {
        const childrenItem = item.children[j];
        childrenItem.component = () => import(`@/views${childrenItem.page}`);
      }
    }
  }
  return mapArray;
};

export const asyncRouterMap = parseJson();
