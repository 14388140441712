<template lang="pug">
    el-dropdown(trigger="click" @command="handleSetSize")
        div: svg-icon(class-name="size-icon" icon-class="size" )
        el-dropdown-menu(slot="dropdown")
            el-dropdown-item(:disabled="size==='medium'" command="medium") 大
            el-dropdown-item(:disabled="size==='small'" command="small") 中
            el-dropdown-item(:disabled="size==='mini'" command="mini") 小

</template>

<script>
export default {
  computed: {
    size() {
      return this.$store.getters.size;
    }
  },
  methods: {
    handleSetSize(size) {
      this.$ELEMENT.size = size;
      this.$store.dispatch("setSize", size);
      this.refreshView();
      this.$message({
        message: "大小已更改",
        type: "success"
      });
    },
    refreshView() {
      // In order to make the cached page re-rendered
      this.$store.dispatch("delAllCachedViews", this.$route);

      const { fullPath } = this.$route;

      this.$nextTick(() => {
        this.$router.replace({
          path: "/redirect" + fullPath
        });
      });
    }
  }
};
</script>

<style scoped>
.size-icon {
  font-size: 20px;
  cursor: pointer;
  vertical-align: 6px !important;
}
</style>
