import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import app from "./modules/app";
import user from "./modules/user";
import tagsView from "./modules/tags-view";
import permission from "./modules/permission";
import getters from "./getters";
export default new Vuex.Store({
  modules: { app, user, tagsView, permission },
  getters
});
