// set function parseTime,formatTime to filter
export { parseTime, formatTime, formatGmt } from "@/utils";

function pluralize(time, label) {
  if (time === 1) {
    return time + label;
  }
  return time + label + "s";
}

export function timeAgo(time) {
  const between = Date.now() / 1000 - Number(time);
  if (between < 3600) {
    return pluralize(~~(between / 60), " minute");
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), " hour");
  } else {
    return pluralize(~~(between / 86400), " day");
  }
}

/* 数字 格式化*/
export function numberFormatter(num, digits) {
  if (!num) return "";
  const si = [
    { value: 1e18, symbol: "E" },
    { value: 1e15, symbol: "P" },
    { value: 1e12, symbol: "T" },
    { value: 1e9, symbol: "G" },
    { value: 1e6, symbol: "M" },
    { value: 1e3, symbol: "k" }
  ];
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (
        (num / si[i].value + 0.1)
          .toFixed(digits)
          .replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[i].symbol
      );
    }
  }
  return num.toString();
}

export function toThousandFilter(num) {
  return (+num || 0)
    .toString()
    .replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ","));
}

export function formatDate(time) {
  if (time != null && time !== "") {
    var date = new Date(time);
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    return year + "-" + month + "-" + day;
  } else {
    return "";
  }
}

function paddingZero(num, length = 2) {
  let rtn = "0000";
  rtn += num;
  return rtn.substr(rtn.length - length, 2);
}
export function formatDateAndTime(time) {
  if (time != null && time !== "") {
    var date = new Date(time);
    var year = date.getFullYear();
    var month = paddingZero(date.getMonth() + 1);
    var day = paddingZero(date.getDate());
    var hour = paddingZero(date.getHours());
    var minute = paddingZero(date.getMinutes());
    var second = paddingZero(date.getSeconds());
    return (
      year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second
    );
  } else {
    return "";
  }
}

export function formatBoolean(value) {
  return value ? "是" : "否";
}
