<template lang="pug">
  span(@click="toggleClick" )
    svg-icon(icon-class="hamburger" 
            :class="{ 'is-active': isActive }").hamburger
</template>

<script>
export default {
  name: "Hamburger",
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    toggleClick: {
      type: Function,
      default: null
    }
  }
};
</script>

<style scoped>
.hamburger {
  display: inline-block;
  cursor: pointer;
  width: 30px !important;
  height: 30px !important;
  margin-top: 10px;
  transition: 0.38s;
  transform-origin: 50% 50%;
}

.hamburger.is-active {
  transform: rotate(180deg);
}
</style>
