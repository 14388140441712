<template lang="pug">
  span(@click="toggleFullScreen" )
    svg-icon(:icon-class="iconClass" class-name="screenfull" )
</template>

<script>
import screenfull from "screenfull";

export default {
  name: "Screenfull",
  props: {
    width: {
      type: Number,
      default: 22
    },
    height: {
      type: Number,
      default: 22
    },
    fill: {
      type: String,
      default: "#48576a"
    }
  },
  data() {
    return {
      iconClass: "expand"
    };
  },
  methods: {
    toggleFullScreen() {
      if (!screenfull.enabled) {
        this.$message({
          message: "you browser can not work",
          type: "warning"
        });
        return false;
      }
      screenfull.toggle();
      this.iconClass = screenfull.isFullscreen ? "expand" : "shrink";
    }
  }
};
</script>

<style scoped>
.screenfull {
  display: inline-block;
  cursor: pointer;
  fill: #5a5e66;
  height: 20px !important;
  width: 20px !important;
  vertical-align: 10px;
}
</style>
