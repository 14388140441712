import request from "@/utils/request";

// 密码登录
export function loginByUsername(username, password) {
  return request({
    url: "/user/login",
    method: "post",
    data: { username, password }
  });
}

// 登出
export function logout(token) {
  return request({
    url: "/user/logout",
    method: "post",
    data: { token }
  });
}

// 取得用户权限
export function getUserInfo(token) {
  return request({
    url: "/user/info",
    method: "post",
    data: { token }
  });
}
