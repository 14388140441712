import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import Cookies from "js-cookie";

import "normalize.css/normalize.css"; // A modern alternative to CSS resets
import "@/styles/index.scss"; // global css
import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import "./router-interceptor";
import "./icons"; // 自己做的svg图标

import permission from "@/directive/permission/index.js"; // 权限判断指令
Vue.use(permission);

import * as filters from "./filters"; // global filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});

Vue.config.productionTip = false;

Vue.use(Element, {
  size: Cookies.get("size") || "mini" // set element-ui default size
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
