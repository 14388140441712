<template lang="pug">
    div(v-if="!item.hidden&&item.children").menu-wrapper

        template(v-if="hasOneShowingChild(item.children,item) && (!onlyOneChild.children||onlyOneChild.noShowingChildren)&&!item.alwaysShow")
            app-link(:to="resolvePath(onlyOneChild.path)")
                el-menu-item(:index="resolvePath(onlyOneChild.path)" 
                            :class="{'submenu-title-noDropdown':!isNest}")
                    item(v-if="onlyOneChild.meta" 
                        :icon="onlyOneChild.meta.icon||item.meta.icon" 
                        :title="onlyOneChild.meta.title" )

        el-submenu(v-else ref="submenu" :index="resolvePath(item.path)")
            //- 主菜单图标
            template(slot="title")
                item(v-if="item.meta" 
                    :icon="item.meta.icon" 
                    :title="item.meta.title")

            template(v-for="child in item.children" v-if="!child.hidden")
                sidebar-item(v-if="child.children&&child.children.length>0"
                            :is-nest="true"
                            :item="child"
                            :key="child.path"
                            :base-path="resolvePath(child.path)").nest-menu
                app-link(v-else :to="resolvePath(child.path)" :key="child.name")
                    el-menu-item(:index="resolvePath(child.path)")
                        item(v-if="child.meta" :icon="child.meta.icon" :title="child.meta.title") 
                

        
</template>

<script>
import path from "path";
import { isExternal } from "@/utils";
import Item from "./item";
import AppLink from "./link";
import FixiOSBug from "./fix-ios-bug-mixin";

export default {
  name: "SidebarItem",
  components: { Item, AppLink },
  mixins: [FixiOSBug],
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      onlyOneChild: null
    };
  },
  methods: {
    hasOneShowingChild(children, parent) {
      const showingChildren = children.filter(item => {
        if (item.hidden) {
          return false;
        } else {
          // Temp set(will be used if only has one showing child)
          this.onlyOneChild = item;
          return true;
        }
      });

      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true;
      }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = { ...parent, path: "", noShowingChildren: true };
        return true;
      }

      return false;
    },
    resolvePath(routePath) {
      if (this.isExternalLink(routePath)) {
        return routePath;
      }
      return path.resolve(this.basePath, routePath);
    },
    isExternalLink(routePath) {
      return isExternal(routePath);
    }
  }
};
</script>
